// @import url("https://www.ft.com/__origami/service/build/v2/bundles/css?modules=o-header-services%402.2.2%2Co-fonts%40%5E3.0.0%2Co-normalise%40%5E1.0.0%2Co-footer-services%40%5E1.0.2&brand=internal");
$o-brand: 'internal';
$polyfill-logo: "../img/logo.svg";

$o-normalise-is-silent: false;
@import 'o-normalise/main.scss';
$o-footer-services-is-silent: false;
@import 'o-footer-services/main.scss';
$o-forms-is-silent: false;
@import 'o-forms/main.scss';
$o-syntax-highlight-is-silent: false;
@import 'o-syntax-highlight/main.scss';
$o-tabs-is-silent: false;
@import 'o-tabs/main.scss';
$o-tooltip-is-silent: false;
@import 'o-tooltip/main.scss';

@import 'o-header-services/main';
@include oHeaderServices($opts:  (
	'types': ('primary-nav'),
	'features': ('drop-down'),
	'logo': $polyfill-logo
));

@import 'o-layout/main';
@include oLayout();

@import 'o-table/main';
@include oTable($opts: (
	'lines',
	'row-headings'
));

@import 'o-buttons/main';

.copy-button {
	@include oButtons($theme: 'mono');
}

.core .o--if-js,
.enhanced .o--if-no-js {
	display: none !important;
}

#features-list [aria-hidden=true] {
	display: none !important;
}

[data-o-component="o-tooltip"] {
	display: none;
}

.o-tabs__tabpanel {
	position: relative;

	button {
		position: absolute;
		right: 0;
		top: -1px;
		background: rgb(255, 255, 255);
	}

	pre {
		margin-top: 0;
		height: 24px;
	}
}

.o-forms {
	padding: 0;
}

.polyfill {
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	padding-left: 8px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	label {
		flex-grow: 1;
	}

	.tooltip-button {
		@include oButtons($theme: 'mono');
		@include oButtonsIconButton(
			$icon-name: 'more',
			$theme: 'mono',
			$is-icon-only: true
		);
		border: 0;
	}
}

.o-tooltip .o-tooltip-content {
	hyphens: unset;
	white-space: nowrap;
	max-height: 50vh;

	> :last-child {
		margin-bottom: 8px;
	}
}
